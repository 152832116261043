
import { defineComponent, ref } from 'vue'
import { ErrorMessage, Field, Form } from 'vee-validate'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import * as Yup from 'yup'
import ApiService from '@/core/services/ApiService'
import JwtService from '@/core/services/JwtService'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { AxiosRequestConfig } from 'axios'
import { useI18n } from 'vue-i18n'
import { event } from 'vue-gtag'

interface Answer {
  id?: number
  qId?: number
  text: string
  isCorrect: boolean
}

interface Question {
  id?: number
  text: string
  answers: Answer[]
}

interface Exercise {
  subject: string
  title: string
  module: string
  deadline: string
  marked: boolean
  trimester: string
  status: string
  questions: Question[]
}

interface Module {
  _id: string
  name: string
  subjects: Subject[]
  status: string
}

interface Subject {
  _id: string
  name: string
  status: string
}

export default defineComponent({
  name: 'edit-exercise',
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  props: {
    widgetClasses: String,
    id: String,
  },
  setup() {
    const { t } = useI18n()
    const submitButtonRef = ref<null | HTMLButtonElement>(null)
    // eslint-disable-next-line
    const upload = ref<any>(null)
    const subjects = ref<Subject[]>([])
    const modules = ref<Module[]>([])

    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const homeworkID = route.params.hid
    const classID = route.params.id

    const jwtTrimester = JwtService.getTrimester()
    let trimester = '1'
    if (jwtTrimester && jwtTrimester != '0') trimester = jwtTrimester

    const exercise = ref<Exercise>({
      subject: '',
      title: '',
      module: '',
      deadline: '',
      marked: true,
      trimester,
      status: '',
      questions: [{ text: '', answers: [{ text: '', isCorrect: false }] }],
    })

    const studentList = ref<any[]>([])
    const getStudents = (students: []) => {
      const sy = window.localStorage.getItem('activeSchoolarYear')
      let match = {}
      match[`schoolarYearsHistory.${sy}`] = classID
      ApiService.post('/students/filter', {
        query: { status: 'active' },
        aggregation: [
          {
            $match: match,
          },
          {
            $set: {
              classRoom: {
                $convert: {
                  input: '$schoolarYearsHistory.' + sy,
                  to: 'objectId',
                  onError: null,
                  onNull: null,
                },
              },
            },
          },
          {
            $project: {
              _id: 1,
              firstName: 1,
              lastName: 1,
            },
          },
        ],
      })
        .then(({ data }) => {
          studentList.value = data.filter((s) =>
            students.find((id) => id == s._id)
          )
        })
        .catch((e) => console.log(e))
    }

    const validationSchema = Yup.object().shape({
      title: Yup.string().required(t('course.requiredQuestion')),
      subject: Yup.string().required(t('course.requiredSubject')),
    })

    ApiService.post('/teacher/classRommDetails', {
      query: {
        teacher: store.getters.currentUser._id,
      },
    })
      .then(({ data }) => {
        subjects.value = []
        modules.value = []
        const classroom = data.find((el) => el.classRoom._id == route.params.id)
        if (classroom) {
          subjects.value = classroom.subjects
          classroom.modulesSpecific.map((m) => {
            if (!modules.value.find((t) => t._id == m._id))
              modules.value.push(m)
          })
          classroom.modules.map((m) => {
            if (!modules.value.find((t) => t._id == m._id))
              modules.value.push(m)
          })
        }

        //remove duplicated
        subjects.value = subjects.value.filter(
          (subject, index) =>
            subjects.value.findIndex((s) => s._id == subject._id) === index &&
            !modules.value.find((m) =>
              m.subjects.find((ss) => ss._id == subject._id)
            ) &&
            subject.status !== 'inactive'
        )
      })
      .catch((e) => console.log(e))

    ApiService.get(`/lms/quiz/${homeworkID}`)
      .then(({ data }) => {
        exercise.value = {
          subject: data.subject._id,
          title: data.title,
          module: data.module,
          marked: data.marked,
          deadline: data.deadline,
          trimester: data.trimester ? data.trimester : '0',
          status: data.status,
          questions: data.questions,
        }

        if (data.students && data.students.length != 0)
          getStudents(data.students)

        if (data.status == 'validated') router.push('/404')
      })
      .catch((e) => {
        console.log(e)
      })

    const submit = () => {
      let test = false

      exercise.value.questions.map((question: Question) => {
        if (question.text == '') {
          test = true
          return
        }
        question.answers.map((answer: Answer) => {
          if (answer.text == '') {
            test = true
            return
          }
        })
      })

      if (test)
        return Swal.fire({
          text: t('course.pleaseFillOrDeleteAnswers'),
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: t('course.tryAgain'),
          customClass: {
            confirmButton: 'btn fw-bold btn-light-danger',
          },
        })

      const data: any = {}

      for (const name in exercise.value) {
        if (!exercise.value.deadline) exercise.value.deadline = ''
        if (!['status'].includes(name)) data[name] = exercise.value[name]
      }

      if (!submitButtonRef.value) {
        return
      }

      let questionId = 0

      exercise.value.questions = exercise.value.questions.map((question) => {
        question.id = questionId++
        let answerId = 0
        question.answers = question.answers.map((answer) => {
          answer.qId = question.id
          answer.id = answerId++
          return answer
        })
        return question
      })

      data.questions = exercise.value.questions

      //Disable button
      submitButtonRef.value.disabled = true
      // Activate indicator
      submitButtonRef.value.setAttribute('data-kt-indicator', 'on')

      ApiService.patch(`/lms/quiz/${homeworkID}`, data as AxiosRequestConfig)
        .then(() => {
          if (submitButtonRef.value) {
            submitButtonRef.value.disabled = false

            submitButtonRef.value?.removeAttribute('data-kt-indicator')
          }

          event('Edit quiz', {
            event_category: 'Exercice',
            event_label: 'Classe profile',
            value: 1,
          })

          Swal.fire({
            text: t('course.editedExerciseInfo'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: t('course.okay'),
            customClass: {
              confirmButton: 'btn btn-primary',
            },
          }).then(() => {
            router.push(`/classe/${classID}/quizDetails/${homeworkID}`)
          })
        })
        .catch(() => {
          Swal.fire({
            text: t('course.errorText'),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: t('course.tryAgain'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          })
          if (submitButtonRef.value) {
            submitButtonRef.value.disabled = false

            submitButtonRef.value?.removeAttribute('data-kt-indicator')
          }
        })
    }

    return {
      t,
      classID,
      homeworkID,
      subjects,
      upload,
      exercise,
      modules,
      validationSchema,
      submit,
      submitButtonRef,
      studentList,
    }
  },
})
